import React, { ReactElement, ReactNode } from "react";

import css from "./style/index.module.scss";

type Props = {
  children: ReactNode;
};

function PageWrapper({ children }: Props): ReactElement {
  return <div className={css.component}>{children}</div>;
}

export default PageWrapper;
