import React from "react";
import classnames from "classnames";

import css from "./style/index.module.scss";

type Props = {
  label: string;
  login?: boolean;
  disabled?: boolean;
  submit?: boolean;
  primary?: boolean;
  onClick?: () => void;
};

function Button({
  label,
  login = false,
  disabled = false,
  submit = false,
  primary = false,
  onClick,
}: Props) {
  return (
    <button
      disabled={disabled}
      type={submit ? "submit" : "button"}
      className={classnames({
        [css.component]: true,
        [css.login]: login,
        [css.primary]: primary,
        [css.submit]: submit,
      })}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default Button;
