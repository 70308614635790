import React, { useContext } from "react";

import css from "./style/index.module.scss";
import Button from "../button";
import { AppContext } from "../../contexts/appContext";
import language from "../../data/language";

function ModalWindow() {
  const { state, dispatch } = useContext(AppContext);
  return (
    <div className={css.component}>
      <div className={css.wrap}>
        <div className={css.content}>{state.error}</div>

        {/* Temp message */}
        {state.error === "The password is incorrect" && (
          <div className={css.extraMessage}>
            Have you reactivated your account?
            <br />
            <a
              href="/#activate"
              onClick={() => dispatch({ type: "HIDE_MESSAGE" })}
            >
              Activate Account
            </a>
            .
          </div>
        )}


        {state.error === "Not able to activate the account" && (
          <div className={css.extraMessage}>
            If you need help please check out our
            <br />
            <a
              href={language.helpUrl}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Help Page
            </a>
            .
          </div>
        )}

        <div className={css.buttons}>
          <Button
            primary={true}
            label="Close"
            onClick={() => dispatch({ type: "HIDE_MESSAGE" })}
          />
        </div>
      </div>
    </div>
  );
}

export default ModalWindow;
