import React, { ReactElement, ReactNode } from "react";

import css from "./style/index.module.scss";

import LoadingLogo from "../../components/loadingLogo";

type Props = {
  children: ReactNode;
};

function Main({ children }: Props): ReactElement {
  return (
    <main className={css.component}>
      <div className={css.logo}>
        <LoadingLogo />
      </div>

      {children}
    </main>
  );
}

export default Main;
