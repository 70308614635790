import React, { useEffect, useState } from "react";
import language from "../../data/language";
import Button from "../button";
import css from "./style/index.module.scss";
import LogoSrc from "../../resources/img/logo.svg";
import api from "../../api";

type Props = {};

interface SystemMessage {
  id: number;
  title: string;
  message: string;
  closeable: boolean;
}

export default function SystemMessageOverlay({}: Props) {
  const [forceRerender, setForceRerender] = useState(0);

  const getSeenMessages = (): SystemMessage["id"][] => {
    const strMessages = localStorage.getItem("sysmsgs") || "";
    return strMessages.split(",").map((s) => Number(s));
  };

  const setSeenMessage = (messageId: SystemMessage["id"]): void => {
    const seenMessages = [...getSeenMessages()];

    if (!seenMessages.includes(Number(messageId))) {
      seenMessages.push(messageId);
      localStorage.setItem("sysmsgs", seenMessages.join(","));
      setForceRerender(forceRerender + 1);
    }
  };

  const [allMessages, setAllMessages] = useState<SystemMessage[]>([]);

  const handleReadNClose = (messageId: SystemMessage["id"]) => {
    setSeenMessage(messageId);
  };

  const findNotSeenMessage = (
    messages: SystemMessage[],
    seenMessages: SystemMessage["id"][]
  ): SystemMessage | undefined => {
    return messages.find(
      ({ id, closeable }) => !closeable || !seenMessages.includes(Number(id))
    );
  };

  const newMessage = findNotSeenMessage(allMessages, getSeenMessages());

  useEffect(() => {
    const handleGetNotifications = async () => {
      const notifcations = await api.fetchNotifications();
      if (!notifcations) {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        return;
      }
      setAllMessages(notifcations);
    };

    handleGetNotifications();
  }, []);

  return newMessage ? (
    <div className={css.root}>
      <div className={css.logo}>
        <img src={LogoSrc} width="250" alt={language.casino} />
      </div>
      <div className={css.wrap}>
        <h1>{newMessage.title}</h1>
        <div
          className={css.message}
          dangerouslySetInnerHTML={{ __html: newMessage.message }}
        />

        {newMessage.closeable && (
          <div className={css.close}>
            <Button
              onClick={() => handleReadNClose(newMessage.id)}
              label={language.close}
              primary={true}
            />
          </div>
        )}
      </div>
    </div>
  ) : null;
}
