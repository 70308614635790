import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import backgroundPath from "../../resources/img/background.jpg";
import css from "./style/index.module.scss";
import ModalWindow from "../../components/modalWindow";
import { AppContext } from "../../contexts/appContext";
import SystemMessageOverlay from "../../components/systemMessageOverlay";

type Props = {
  children: ReactNode;
};

function Wrapper({ children }: Props): ReactElement {
  const { state } = useContext(AppContext);
  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    const image = new Image();
    image.src = backgroundPath;
    image.onload = () => {
      setBackgroundImage(backgroundPath);
    };
  }, []);

  var image = new Image();
  image.src = backgroundPath;

  return (
    <div
      className={css.component}
      style={
        image.complete || backgroundImage
          ? { backgroundImage: `url(${backgroundPath})` }
          : {}
      }
    >
      {state.error && <ModalWindow />}
      <SystemMessageOverlay />
      {children}
    </div>
  );
}

export default Wrapper;
