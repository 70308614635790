import React from "react";
import AppWrapper from "./containers/wrapper";
import Window from "./containers/window";

import Aside from "./containers/aside";
import { AppProvider } from "./contexts/appContext";
import Router from "./router";
import { CSRF_KEY } from "./config";
import api from "./api";

// import ModalReactivateAccount from "./components/modalReactivateAccount";
function App() {
  try {
    localStorage.setItem(CSRF_KEY, btoa(new Date().getTime().toString()));

    api.authorizationСheck().then((data) => {
      if (data === true) {
        window.location.href = "/login";
      }
    });

    // To set up uid cookie, send the request every time,
    // but the cookie is installed only once.
    fetch("/endpoint/stat/create").catch((_e) => {});
  } catch (e) {}
  return (
    <AppProvider>
      <AppWrapper>
        {/* <ModalReactivateAccount /> */}
        <Window>
          <Router />
          <Aside />
        </Window>
      </AppWrapper>
    </AppProvider>
  );
}

export default App;
