import React from "react";

import css from "./style/index.module.scss";

type Props = {
  content: string;
};

function Title({ content }: Props) {
  return <h1 className={css.component}>{content}</h1>;
}

export default Title;
