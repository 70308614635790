export default {
  resetLinkSucces:
    "If an account exists, we will send you a link to reset your password.",
  welcome: `Welcome to the club. Sign in to access your exclusive Crescent Club Rewards.`,
  crescentClub: `Crescent Club members earn valuable rewards based on slot and table
  games.`,
  memberBenefitsLink: " Click here to learn more about tier benefits.",
  needHelp: `Need help?`,
  casino: `Snoqualmie Casino & Hotel`,
  forgotPassword: `Forgot password?`,
  cardNumber: `Crescent Club Card Number`,
  email: `Email`,
  recoveryLinkButton: "Submit",
  resetPasswordButton: "Reset Password",
  submitButton: "Submit",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  passwordMustBe: `Password must be at least eight characters including one uppercase letter,
  one special character (!, @, #, $, %, ^, &)`,
  passwordsNotMatch: "Passwords not match",
  somethingWentWrong: "Something went wrong try again later",
  passwordChanged: "Password has been changed successfull",
  activateAccount: "Activate Account",
  password: "Password",
  confirmPassword: "Confirm Password",
  zipCode: "ZIP Code",
  birthdate: "Birthdate: (MM/DD/YYYY)",
  mm: "MM",
  dd: "DD",
  yyyy: "YYYY",
  allFieldsrequired: "All fields are required.",
  activateDescription:
    "For security purposes, please provide the following information. Your answers must match that we have on file.",

  accountActivated: `Your account has been activated successfully. You can now login.`,
  activationLinkSent: `An activation link has been sent to your email address. Please follow that link to finish your registration process.`,
  helpUrl: "https://www.snocasino.com/casino/frequently-asked-questions",
  close: "Close",
};
