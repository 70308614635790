import React from "react";
import classnames from "classnames";

import css from "./style/index.module.scss";

type Props = {
  value: string;
  label: string;
  uniqueId: string;
  required?: boolean;
  type?: string;
  maxLength?: number;
  minLength?: number;
  onChange: (value: string) => void;
};

function Input({
  value,
  label,
  uniqueId,
  required = false,
  type = "text",
  maxLength,
  minLength,
  onChange,
}: Props) {
  return (
    <fieldset className={css.component}>
      <label
        htmlFor={uniqueId}
        className={classnames({ [css.label]: true, [css.active]: value })}
      >
        {label}
      </label>
      <input
        className={classnames({ [css.input]: true })}
        id={uniqueId}
        required={required}
        type={type}
        value={value}
        maxLength={maxLength}
        minLength={minLength}
        onChange={({ target }) => onChange(target.value)}
      />
    </fieldset>
  );
}

export default Input;
