import React, { useEffect, useState } from "react";
import Main from "./containers/main";
import PageWrapper from "./containers/pageWrapper";

import Switcher from "./components/switcher";
import Footer from "./components/footer";

import LoginPage from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import ActivateAccount from "./pages/activateAccount";

function Router() {
  const [route, setRoute] = useState("login");
  const [resetCode, setResetCode] = useState("");

  useEffect(() => {
    const hasChange = () => {
      const newRoute = window.location.hash.substring(1).split("-");

      if (newRoute[0] === "reset") {
        if (newRoute[1]) {
          setResetCode(newRoute[1]);
        } else {
          window.location.hash = "";
        }
      }

      if (newRoute[0] !== route) setRoute(newRoute[0]);
    };
    window.addEventListener("hashchange", hasChange);
    hasChange();
    return () => {
      window.removeEventListener("hashchange", hasChange);
    };
  }, [route]);

  const renderLogin = () => (
    <>
      <header>
        <Switcher current="login" />
      </header>
      <PageWrapper>
        <LoginPage />
      </PageWrapper>
    </>
  );

  const renderActivate = () => (
    <>
      <header>
        <Switcher current="activate" />
      </header>
      <PageWrapper>
        <ActivateAccount />
      </PageWrapper>
    </>
  );

  const renderForgot = () => (
    <>
      <header>
        <Switcher />
      </header>
      <PageWrapper>
        <ForgotPassword />
      </PageWrapper>
    </>
  );
  const renderReset = () => (
    <>
      <header>
        <Switcher />
      </header>
      <PageWrapper>
        <ResetPassword code={resetCode} />
      </PageWrapper>
    </>
  );

  const renderRoute = () => {
    if (route === "activate") {
      return renderActivate();
    } else if (route === "forgot") {
      return renderForgot();
    } else if (route === "reset") {
      return renderReset();
    }

    return renderLogin();
  };
  return (
    <Main>
      {renderRoute()} <Footer />
    </Main>
  );
}

export default Router;
