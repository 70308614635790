import React from "react";

import css from "./style/index.module.scss";
import Button from "../../components/button";
import LogoSrc from "../../resources/img/logo.svg";
import language from "../../data/language";

function Aside() {
  return (
    <aside className={css.component}>
      <div className={css.wrap}>
        <div className={css.logo}>
          <img src={LogoSrc} width="300" alt={language.casino} />
        </div>
        <div className={css.description}>{language.welcome}</div>
        <div className={css.buttons}>
          <a href={language.helpUrl} target="_blank" rel="noopener noreferrer">
            <Button label={language.needHelp}></Button>
          </a>
        </div>
      </div>
    </aside>
  );
}

export default Aside;
