import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "./resources/style/index.css";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import { initGA, pageViewGA } from "./stat";

initGA();
pageViewGA();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

const onSWUpdate = (registration: ServiceWorkerRegistration) => {
  registration.waiting?.postMessage({ type: "SKIP_WAITING" });
  window.location.reload();
};

serviceWorker.register({ onUpdate: onSWUpdate });
