import language from "../data/language";

export const passwordsValidation = (pass1: string, pass2: string) => {
  if (pass1 !== pass2) {
    throw new Error(language.passwordsNotMatch);
  }
  if (!pass1.match(/^(?=.*[A-Z])(?=.*[!#$%&()*+-/<=>?@[\]^_{|}~]).{8,}$/)) {
    throw new Error(language.passwordMustBe);
  }
  return true;
};

export const formatMaxNumber = (value: string, max: number) => {
  value = value.substr(0, String(max).length + 1).replace(/\D/g, "");
  if (Number(value) > max) value = "";

  return value;
};
