// type IRoutes = "login" | "activate" | "reset";

export interface IMessageAction {
  type: "SHOW_LOADING" | "HIDE_LOADING" | "HIDE_MESSAGE";
}

export interface IShowErrorAction {
  type: "SHOW_MESSAGE";
  payload: string;
}

export type Actions = IMessageAction | IShowErrorAction;

export interface IState {
  isLoading: boolean;
  error: string | null;
  // route: IRoutes;
}

export const initialState: IState = {
  isLoading: false,
  error: null,
  // route: "login",
};

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case "SHOW_LOADING":
      return { ...state, isLoading: true };
    case "HIDE_LOADING":
      return { ...state, isLoading: false };
    case "SHOW_MESSAGE":
      return { ...state, error: action.payload };
    case "HIDE_MESSAGE":
      return { ...state, error: null };
    default:
      return state;
  }
};
