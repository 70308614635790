import { CSRF_KEY } from "../config";
import language from "../data/language";

const apiUrl =
  process.env.NODE_ENV === "development" ? "http://localhost:5004" : "";

interface IApiSubmit {
  data: {};
  url: string;
}
interface IApiAuth {
  login: string;
  password: string;
}
interface IApiForgotPassword {
  email: string;
}
interface IApiResetPassword {
  password: string;
  code: string;
}
interface IApiActivateAccount {
  cardNumber: string;
  email: string;
  password: string;
  dob: string;
  zip: string;
}

const submit = ({ url, data }: IApiSubmit) => {
  return fetch(`${apiUrl}${url}`, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": localStorage.getItem(CSRF_KEY) || "",
    },
  })
    .then(async (response) => {
      try {
        const data = await response.json();
        return data;
      } catch (e) {
        throw new Error(language.somethingWentWrong);
      }
    })
    .then((data) => {
      if (data.status === "ok") {
        return data;
      }
      let message = language.somethingWentWrong;
      if (data.status === "error" && data.message) {
        message = data.message;
      }
      throw new Error(message);
    });
};

const auth = async (data: IApiAuth) => {
  return submit({
    data,
    url: "/auth",
  });
};

const forogtPassword = async (data: IApiForgotPassword) => {
  return submit({
    data,
    url: "/forgot-password",
  });
};

const resetPassword = async (data: IApiResetPassword) => {
  return submit({
    data,
    url: "/reset-password",
  });
};

const activateAccount = async (data: IApiActivateAccount) => {
  return submit({
    data,
    url: "/activate",
  });
};

const authorizationСheck = async () => {
  try {
    const response = await fetch(`${apiUrl}/check`, {
      headers: {
        "X-CSRF-TOKEN": localStorage.getItem(CSRF_KEY) || "",
      },
    });
    const text = await response.text(); // Parse it as text
    const data = JSON.parse(text);
    if (data && data.status === "ok" && data.isLoggedIn) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

const fetchNotifications = async () => {
  try {
    const response = await fetch(`${apiUrl}/notifications`, {
      headers: {
        "X-CSRF-TOKEN": localStorage.getItem(CSRF_KEY) || "",
      },
    });
    const data = await response.json();
    if (data && data.status === "ok" && data.notifications) {
      return data.notifications;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export default {
  auth,
  forogtPassword,
  resetPassword,
  activateAccount,
  authorizationСheck,
  fetchNotifications,
};
