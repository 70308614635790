import React, { ReactElement, useContext, useState } from "react";
import api from "../../api";
import Button from "../../components/button";
import Input from "../../components/input";
import LogoMobile from "../../components/logoMobile";
import Title from "../../components/titel";
import { AppContext } from "../../contexts/appContext";
import language from "../../data/language";
import css from "./style/index.module.scss";

function ForgotPassword(): ReactElement {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { dispatch } = useContext(AppContext);

  const onEmailChange = (value: string) => setEmail(value);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) return;

    dispatch({ type: "SHOW_LOADING" });
    setIsSubmitting(true);
    api
      .forogtPassword({ email })
      .then(() => {
        dispatch({
          type: "SHOW_MESSAGE",
          payload: language.resetLinkSucces,
        });
        window.location.hash = "";
      })
      .catch((e) => {
        dispatch({ type: "SHOW_MESSAGE", payload: e.message });
      })
      .finally(() => {
        setIsSubmitting(false);
        dispatch({ type: "HIDE_LOADING" });
      });
  };
  return (
    <article>
      <LogoMobile />
      <Title content={language.forgotPassword} />

      <form method="POST" action="/dreamcatcher/forgot" onSubmit={handleSubmit}>
        <div className={css.field}>
          <Input
            onChange={onEmailChange}
            value={email}
            uniqueId="f-email"
            label={language.email}
            type="email"
            required={true}
          />
        </div>
        <div className={css.submit}>
          <Button
            submit={true}
            label={language.recoveryLinkButton}
            login={true}
            disabled={isSubmitting}
          />
        </div>
      </form>
    </article>
  );
}

export default ForgotPassword;
