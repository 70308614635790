import React, { ReactElement, useState, useContext } from "react";

import css from "./style/index.module.scss";

import Title from "../../components/titel";
import Input from "../../components/input";
import Button from "../../components/button";

import LogoMobile from "../../components/logoMobile";
import { AppContext } from "../../contexts/appContext";
import api from "../../api";
import language from "../../data/language";

function LoginPage(): ReactElement {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { dispatch } = useContext(AppContext);

  const onLoginChange = (value: string) => {
    setLogin(value);
  };
  const onPasswordChange = (value: string) => {
    setPassword(value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!(login && password)) return;

    dispatch({ type: "SHOW_LOADING" });
    setIsSubmitting(true);
    api
      .auth({ login, password })
      .then(() => {
        window.location.href = "/login";
      })
      .catch((e) => {
        dispatch({ type: "SHOW_MESSAGE", payload: e.message });
        setPassword("");
        setIsSubmitting(false);
        dispatch({ type: "HIDE_LOADING" });
      });
  };

  return (
    <article>
      <LogoMobile />
      <Title content="Login" />

      <form method="POST" action="/dreamcatcher/login" onSubmit={handleSubmit}>
        <div className={css.field}>
          <Input
            onChange={onLoginChange}
            value={login}
            uniqueId="l-login"
            label="Email or card number"
            required={true}
          />
        </div>
        <div className={css.field}>
          <Input
            onChange={onPasswordChange}
            value={password}
            uniqueId="l-password"
            label="Password"
            type="password"
            required={true}
          />
        </div>
        <div className={css.submit}>
          <Button
            submit={true}
            label="LOGIN"
            login={true}
            disabled={isSubmitting}
          />
        </div>
        <div className={css.memberBenefits}>
          <p>
            {/* {language.crescentClub}{" "} */}
            <a
              rel="noreferrer noopener"
              href="https://www.snocasino.com/member-benefits"
            >
              {language.memberBenefitsLink}
            </a>
          </p>
        </div>
      </form>
    </article>
  );
}

export default LoginPage;
