import React, { useContext } from "react";
import classnames from "classnames";

import { AppContext } from "../../contexts/appContext";

import css from "./style/index.module.scss";
import LogoRoundSrc from "../../resources/img/512x512_s.png";
import language from "../../data/language";

function LoadingLogo() {
  const { state } = useContext(AppContext);
  return (
    <div
      className={classnames({
        [css.component]: true,
        [css.animation]: state.isLoading,
      })}
    >
      <img src={LogoRoundSrc} width="100" height="100" alt={language.casino} />
    </div>
  );
}

export default LoadingLogo;
