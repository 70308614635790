import React, { useReducer } from "react";
import { Actions, IState, initialState, reducer } from "./appReducer";
import { Dispatch } from "react";

export interface IAppContextProps {
  state: IState;
  dispatch: Dispatch<Actions>;
}

export const AppContext = React.createContext({} as IAppContextProps);

export function AppProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}
