import React from "react";

import css from "./style/index.module.scss";

import LogoSrc from "../../resources/img/logo.svg";

function LogoMobile() {
  return (
    <div className={css.component}>
      <img src={LogoSrc} width="300" alt="Snoqualmie Casino & Hotel" />
    </div>
  );
}

export default LogoMobile;
