import React from "react";
import classnames from "classnames";

import css from "./style/index.module.scss";

type Props = {
  current?: "login" | "activate";
};

function Switcher({ current }: Props) {
  return (
    <ul className={classnames({ [css.component]: true })}>
      <a href="#login">
        <li
          className={classnames({
            [css.option]: true,
            [css.active]: current === "login",
          })}
        >
          Login
        </li>
      </a>
      <a href="#activate">
        <li
          className={classnames({
            [css.option]: true,
            [css.active]: current === "activate",
          })}
        >
          Activate Account
        </li>
      </a>
    </ul>
  );
}

export default Switcher;
